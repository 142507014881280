import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Now`}</h1>
    <p>{`Over the last few years I've been working closely with a lot of companies embracing headless commerce, headless APIs, and the Jamstack.`}</p>
    <p>{`I'm currently working with `}<a parentName="p" {...{
        "href": "https://graphcms.com"
      }}>{`GraphCMS`}</a>{` leading the Developer Relations team. I spend most of my days writing documentation, working as a marketing engineer, speaking with customers, maintaining SDKs, and working with the community.`}</p>
    <p>{`When I'm not working, you'll find me downtown `}<a parentName="p" {...{
        "href": "https://callofduty.fandom.com/wiki/Verdansk"
      }}>{`Verdansk`}</a>{`.`}</p>
    <h2>{`Side projects`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://headlessdropshipping.com"
        }}>{`Headless Dropshipping`}</a>{` `}{`—`}{` Launch your own dropshipping store with Next.js, Printful, and Snipcart`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://graphql.wtf"
        }}>{`GraphQL.wtf`}</a>{` `}{`—`}{` A GraphQL focused video tutorial site.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cartql.com"
        }}>{`CartQL`}</a>{` `}{`—`}{` A GraphQL Shopping Cart API that works with your existing product inventory`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://graphiqlbin.com"
        }}>{`GraphiQLBin`}</a>{` `}{`—`}{` Save and share your GraphQL queries with this hosted GraphiQL`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://headlesscommerce.org"
        }}>{`Headless Commerce Resources`}</a>{` `}{`—`}{` A community curated list of commerce products, services, and agencies`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/notrab/awesome-headless-commerce"
        }}><inlineCode parentName="a">{`awesome-headless-commerce`}</inlineCode></a>{` `}{`—`}{` The same as above, but on GitHub for devs.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://buildyourdxp.com"
        }}>{`Build Your DXP`}</a>{` `}{`—`}{` A a community-curated catalog of services that power today's Digital Experience Platforms`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jamstackbb.dev"
        }}>{`JamtackBB`}</a>{` `}{`—`}{` Simple forum software powered by Next.js, GraphQL and Hasura`}</li>
    </ul>
    <h2>{`Previous side projects`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jamcommerce.dev"
        }}>{`JAMCommerce Radio`}</a>{` `}{`—`}{` A podcast for developers and marketers with an interest in commerce`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://podcasts.apple.com/gb/podcast/dev-on-the-tyne/id1497049763"
        }}>{`Dev on the Tyne`}</a>{` `}{`—`}{` My personal podcast about tech, gaming and more, with friend, and colleague Jonathan Steele`}</li>
    </ul>
    <p>{`Want to collaborate on something? `}{`—`}{` I'm always looking to learn, teach, and build.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      